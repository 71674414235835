<messages>["./UserCreateUpdate"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw0>
    <!-- "user does not exist" alert -->
    <v-col cols="12">
      <v-alert
        type="error"
        :value="!isLoading && !user">
        {{ $t ('invalidUserId', {id}) }}
      </v-alert>
    </v-col>

    <v-col v-if="user" cols="12">
      <v-card>
        <v-card-title primary-title>
          <div>
            <div
              v-t="'title.view'"
              class="text-h5"/>
            <div
              v-t="`subtitle.view`"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>

        <v-card-text v-if="!isLoading" class="px-4">
          <name-value
            :name="$t('name')"
            :value="user.name"/>
          <name-value
            :name="$t('active')"
            :value="user.active"/>
          <name-value
            v-if="parentName"
            :name="$t('client')"
            :value="parentName"/>
          <name-value
            :name="$t('displayName')"
            :value="user.displayName"/>
          <name-value
            :name="$t('email')"
            :value="user.emailAddress"/>
          <name-value
            :name="$t('preferredLanguage')"
            :value="$t(`preferredLanguages.${user.preferredLanguage}`)"/>
          <name-value :name="$t('groups')">
            <template #value>
              <v-row align="center">
                <v-col>{{ userGroupNames }}</v-col>
                <v-col class="shrink">
                  <user-group-info-menu
                    :available-groups="userGroups"
                    :selected-groups="user.groups"/>
                </v-col>
              </v-row>
            </template>
          </name-value>
          <name-value
            :name="$t('totp.secretPresent')"
            :value="user.totpSecretPresent"/>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.back'"
            text
            @click="back"/>
          <v-btn
            v-if="isEditButtonVisible"
            v-t="'general.button.edit'"
            color="primary"
            :to="{name: 'user.edit', params: {id}}"/>
        </v-card-actions>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapState, mapGetters, mapActions} from 'vuex'
  import BaseLayout from '@/app/core/components/BaseLayout'
  import NameValue from '@/app/core/components/NameValue'

  import UserGroupInfoMenu from './components/UserGroupInfoMenu'

  export default {
    name: 'UserView',

    components: {
      BaseLayout,
      NameValue,
      UserGroupInfoMenu
    },

    props: {
      id: {
        type: [Number, String],
        default: undefined
      }
    },

    data () {
      return {
        isLoading: true,
        isEditButtonVisible: false,
        user: undefined,
        parentName: '',
        userPermissions: [],
        userGroups: []
      }
    },

    computed: {
      ...mapState ('auth', [
        'userLoginData'
      ]),
      ...mapGetters ('auth', [
        'hasAllOfPermissions',
        'isClientless'
      ]),

      userGroupNames () {
        let groupNames = []
        if (this.user.groups?.length) {
          if (this.userGroups.length) {
            groupNames = this.userGroups.filter (g =>
              this.user.groups.includes (g.id)).map (g => g.name)
          } else {
            groupNames = this.user.groups
          }
        }

        return groupNames.reduce ((acc, p) => acc === '' ? p : acc + ', ' + p, '')
      }
    },

    watch: {
      id (newValue) {
        this.loadUser (newValue)
      }
    },

    created () {
      this.loadUser (this.id)
    },

    methods: {
      ...mapActions ({
        getClientNameExt: 'cache/getClientNameExt',
        fetchData: 'request/fetchData'
      }),

      loadUser (id) {
        this.isLoading = true
        this.user = undefined

        return this.fetchData ({
          op: 'user/load',
          params: {id},
          cb: data => {
            this.user = data.userData

            if (this.user.clientId) {
              this.loadClientInfo (this.user.clientId)
            }

            this.loadUserGroups ()
            this.loadUserPermissions (this.user.name)
          },
          cbError: () => {
            this.user = null
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      async loadClientInfo (clientId) {
        this.parentName = await this.getClientNameExt (clientId)
      },

      // retrieve the list of user groups
      loadUserGroups () {
        return this.fetchData ({
          op: 'user/listGroups',
          cb: data => {
            this.userGroups = data.list
          }
        })
      },

      async loadUserPermissions (userName) {
        return this.fetchData ({
          op: 'user/perms',
          params: {names: [userName]},
          cb: data => {
            this.userPermissions = data.userPermissionData[0].userPerms
            if (this.isClientless || this.hasAllOfPermissions (this.userPermissions)) {
              this.isEditButtonVisible = true
            }
          }
        })
      },

      back () {
        this.$router.back ()
      }
    }
  }
</script>
